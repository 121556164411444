
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
import BuiltinService from "../../../services/buitin-service";
import ExportOptions from "@/components/export-options.vue";
import FilterComponent from "@/components/sidebar-employee-filter.vue";
import FilterService from "../../../services/filter-service";
import buitinService from '@/services/buitin-service'
import Moment from "moment";
import PopUp from "@/components/PopUp.vue";

@Component({
  components: {
    Header,
    Sidebar,
    Loader,
    ExportOptions,
    FilterComponent,
    PopUp,

  },
})
export default class Employee extends Vue {
  loading = true;
  public filter = null;
  public filterOn = [];
  public import_response = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  public fields = [
    { key: "code", sortable: true },
    { key: "title", sortable: false },
    { key: "nationality", sortable: false },
    { key: "actual_designation", sortable: false, label: "Designation" },
    { key: "joined_date", sortable: false, label: "DOJ" },
    { key: "contact_num", sortable: false, label: "Contact" },
  ];
  public columns = [
    {
      label: "Code",
      field: "code",
    },
    {
      label: "Title",
      field: "title",
    },
    {
      label: "Nationality",
      field: "nationality",
    },
    {
      label: "Designation",
      field: "actual_designation",
    },
    {
      label: "DOJ",
      field: "joined_date",
    },
    {
      label: "Contact",
      field: "contact_num",
    },
  ];
  public widths = [55, 100, 55, 100, 70, 80];
  public selected_expat = "all";
  public option_expat = [
    { text: "All Employees", value: "all" },
    { text: "Saudi", value: "saudi" },
    { text: "Non Saudi", value: "Non Saudi" },
  ];
  public option_dept: any = [];
  public selected_dept = 0;
  public option_emptype: any = [];
  public selected_emptype = 0;
  public option_branch: any = [];
  public selected_branch = 0;
  public option_bloodgroup: any = [];
  public selected_bloodgroup = 0;
  public selected_effective_date_from = "";
  public selected_effective_date_to = "";
  public selected_items = [];
  public items = [];
  public currentPage = 1;
  get rows() {
    return this.selected_items.length;
  }
  public import_file: any = null;

  retrieve() {
    this.loading = true;
    this.import_response = "";
    HRMService.getAll()
      .then((response) => {
        this.items = response.data;
        this.selected_items = this.items;
        this.loading = false;
        // console.log(this.selected_items);
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
  }
  submit_import() {
    const isValidFile = this.validateFile(this.import_file);
    if(!isValidFile){
      this.showModal("Invalid file. Please upload a valid file by download the sample.",[],"error")
    }else {
    this.import_response = "Please wait, it will take time.";
    this.loading = true;
    this.import_response = "";
    if (this.import_file != null) {
      HRMService.post_employee_file(this.import_file)
        .then((response) => {
          this.retrieve();

          this.import_response = "Uploaded.";
          this.loading = false;
          this.showModal('Uploaded Successfully', [], 'success')


        })
        .catch((e) => {
          if (e.response.data.message) {
            // console.log(e.response.data)
            this.import_response = e.response.data.message;
            this.showModal("Not Uploaded, Some Following Are The Errors.\n Please Check Below The Import Button", [], 'error')
            this.loading = false;
          }
          else {
            this.import_response = "Error occured while uploading.";
            this.loading = false;
            console.log(e);
            this.showModal(e.response.data, [], 'error')
          }
        });
    } else {
      this.import_response = "Please upload the file";
      this.loading = false;
    }
  }
  }
  refreshList() {
    this.get_filters();
    this.retrieve();
  }
  expatchange() {
    if (this.selected_expat == "all") {
      this.selected_items = this.items;
    } else {
      this.selected_items = this.items.filter(
        (x) => x["expats"] == this.selected_expat
      );
    }
  }
  bloodgroupchange() {
    if (this.selected_bloodgroup == 0) {
      this.selected_items = this.items;
    } else {
      let selectedoption = this.option_bloodgroup.filter(
        (y: any) => y["value"] == this.selected_bloodgroup
      );
      this.selected_items = this.items.filter(
        (x) => x["blood_group"] == selectedoption[0]["text"]
      );
    }
  }
  effectivedateto() {
    if (this.selected_effective_date_to) {
      this.selected_items = this.items.filter(
        (x) =>
          parseInt(x["employment_effective_date"]) <
          parseInt(this.selected_effective_date_to)
      );
    }
  }
  effectivedatefrom() {
    if (this.selected_effective_date_from) {
      this.selected_items = this.items.filter(
        (x) =>
          parseInt(x["employment_effective_date"]) <
          parseInt(this.selected_effective_date_from)
      );
    }
  }
  onFiltered(filteredItems: any) { }
  mounted() {
    this.retrieve();
    this.get_filters();
    // console.log(localStorage.getItem('jwt'))
  }
  get_filters() {
    this.loading = true;
    BuiltinService.getallbuiltin()
      .then((response) => {
        this.option_dept = [];
        let dept: any = response.data["department"];
        this.option_dept = dept.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_dept.push({ value: 0, text: "Please select an option" });
        this.option_bloodgroup = [];
        let bloodgroup = response.data["blood_group"];
        this.option_bloodgroup = bloodgroup.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_bloodgroup.push({
          value: 0,
          text: "Please select an option",
        });
        this.option_emptype = [];
        let employment_type = response.data["employment_type"];
        this.option_emptype = employment_type.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_emptype.push({ value: 0, text: "Please select an option" });
        this.option_branch = [];
        let branch = response.data["branch"];
        this.option_branch = branch.map((item: any) => {
          return { value: item.id, text: item.title };
        });
        this.option_branch.push({ value: 0, text: "Please select an option" });
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  onRowClicked(item: any, index: any, event: any) {
    this.$router.push({ name: "Create", params: { id: item.id.toString() } });
  }
  newEmployee() {
    this.$router.push({ name: "Create", params: { id: '' } });
  }

  filter_fields = [
    {
      type: "text",
      label: "Code",
      name: "code",
      value: "",
      param_name: "emp_code",
      filter_type: "simple",
    },
    {
      type: "text",
      label: "Employee Title",
      name: "title",
      value: "",
      param_name: "emp_title",
      filter_type: "simple",
    },
    {
      type: "dropdown",
      label: "Nationality",
      name: "nationality",
      value: "",
      param_name: "nationality",
      options: [
        "British",
        "Argentinian",
        "Australian",
        "Bahamian",
        "Belgian",
        "Brazilian",
        "Canadian",
        "Chinese",
        "Colombian",
        "Cuban",
        "Dominican",
        "Ecuadorean",
        "Salvadorean",
        "French",
        "German",
        "Guatemalan",
        "Haitian",
        "Honduran",
        "Indian",
        "Ireland",
        "Israeli",
        "Italian",
        "Japanese",
        "South Korean",
        "Mexican",
        "Dutch",
        "Philippine",
        "Spanish",
        "Swedish",
        "Swiss",
        "Taiwanese",
        "Venezuelan",
        "Vietnamese",
        "Afghan",
        "Albanian",
        "Algerian",
        "Samoan",
        "Andorran",
        "Angolan",
        "Armenian",
        "Austrian",
        "Azerbaijani",
        "Bahraini",
        "Bangladeshi",
        "Barbadian",
        "Belarusian",
        "Belizean",
        "Beninese",
        "Bermudian",
        "Bhutanese",
        "Bolivian",
        "Bosnian",
        "Botswanan",
        "Bulgarian",
        "Burkinese",
        "Burundian",
        "Cambodian",
        "Cameroonian",
        "Cape Verdean",
        "Chadian",
        "Chilean",
        "Congolese",
        "Costa Rican",
        "Croat",
        "Cypriot",
        "Czech",
        "Danish",
        "Djiboutian",
        "Dominican",
        "Egyptian",
        "Eritrean",
        "Estonian",
        "Ethiopian",
        "Fijian",
        "Finnish",
        "Polynesian",
        "Gabonese",
        "Gambian",
        "Georgian",
        "Ghanaian",
        "Greek",
        "Grenadian",
        "Guinean",
        "Guyanese",
        "Hungarian",
        "Icelandic",
        "Indonesian",
        "Iranian",
        "Iraqi",
        "Jamaican",
        "Jordanian",
        "Kazakh",
        "Kenyan",
        "North Korean",
        "Kuwaiti",
        "Latvian",
        "Lebanese",
        "Liberian",
        "Libyan",
        "Lithuanian",
        "LUXEMBOURG",
        "Madagascan",
        "Malawian",
        "Malaysian",
        "Maldivian",
        "Malian",
        "Maltese",
        "Mauritanian",
        "Mauritian",
        "Monacan",
        "Mongolian",
        "Montenegrin",
        "Moroccan",
        "Mozambican",
        "Namibian",
        "Nepalese",
        "New Zealand",
        "Nicaraguan",
        "Nigerien",
        "Nigerian",
        "Norwegian",
        "Omani",
        "Pakistani",
        "Panamanian",
        "Guinean",
        "Paraguayan",
        "Peruvian",
        "Polish",
        "Portuguese",
        "Qatari",
        "Romanian",
        "Rwandan",
        "Saudi",
        "Senegalese",
        "Serb or Serbian",
        "Sierra Leonian",
        "Singaporean",
        "Slovak",
        "Slovenian",
        "Slomoni",
        "Somali",
        "South African",
        "Sri Lankan",
        "Sudanese",
        "Surinamese",
        "Swazi",
        "Tajik",
        "Thai",
        "Togolese",
        "Trinidadian",
        "Tunisian",
        "Turkish",
        "Turkoman",
        "Tuvaluan",
        "Ugandan",
        "Ukrainian",
        "Emirati",
        "American",
        "Uruguayan",
        "Uzbek",
        "Vanuatuan",
        "Yemeni",
        "Zambian",
      ],
      filter_type: "simple",
    },
    {
      type: "text",
      label: "Designation",
      name: "designation",
      value: "",
      param_name: "actual_designation",
      filter_type: "simple",
    },
    {
      type: "date",
      label: "Date of Joining",
      name: "doj",
      value: "",
      param_name: "joined_date",
      filter_type: "simple",
    },
    // {
    //   type: "text",
    //   label: "Contact",
    //   name: "contact",
    //   value: "",
    //   param_name: "contact_num",
    //   filter_type: "simple",
    // },
    {
      type: "date",
      label: "Date of Birth",
      name: "dob",
      value: "",
      param_name: "birth_date",
      filter_type: "simple",
    },
    {
      type: "date",
      label: "Effective Date",
      name: "eff_date",
      value: "",
      param_name: "employment_effective_date",
      filter_type: "simple",
    },
    {
      type: "number",
      label: "Contact",
      name: "contact",
      value: "",
      param_name: "contact_num",
      filter_type: "simple",
    },
    {
      type: "number",
      label: "Year of Experience",
      name: "year_exp",
      value: "",
      param_name: "years_of_experience",
      filter_type: "simple",
    },
  ];
  advance_filter_fields = [
    {
      type: "dropdown",
      label: "Blood Group",
      name: "blood_group",
      value: "",
      options: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      param_name: "blood_group",
      filter_type: "advance",
    },
    {
      type: "date",
      label: "Date of Birth",
      min_param_name: "start_birth_date",
      max_param_name: "end_birth_date",
      start: "",
      end: "",
      filter_type: "advance",
      range: [],
    },
    {
      type: "date",
      label: "Date of Joining",
      min_param_name: "start_joined_date",
      max_param_name: "end_joined_date",
      start: "",
      end: "",
      filter_type: "advance",
      range: [],
    },
    {
      type: "date",
      label: "Effective Date",
      min_param_name: "start_employment_effective_date",
      max_param_name: "end_employment_effective_date",
      start: "",
      end: "",
      filter_type: "advance",
      range: [],
    },
    {
      type: "range",
      label: "Year of Experience",
      min_param_name: "min_years_of_experience",
      max_param_name: "max_years_of_experience",
      value_2: [0, 10],
      min: 0,
      max: 50,
      filter_type: "advance",
    },
  ];

  handleFilterChange(element: any) {
    if (element.filter_type === "simple") {
      interface SimpleObj {
        [key: string]: any;
      }
      const simpleFilteredObj: SimpleObj = {};
      this.filter_fields.forEach((filter) => {
        simpleFilteredObj[filter.param_name] = filter.value;
      });
      this.loading = true;
      FilterService.getAll(simpleFilteredObj).then((data) => {
        if (data.data.employee !== undefined && data.data.employee !== null) {
          this.selected_items = data.data.employee;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    } else if (element.filter_type === "advance") {
      interface AdvanceObj {
        [key: string]: any;
      }
      const advanceFilteredObj: AdvanceObj = {};
      this.advance_filter_fields.forEach((filter) => {
        if (filter.type === "text") {
          if (filter.param_name) {
            advanceFilteredObj[filter.param_name] = filter.value;
          }
        }
        if (filter.type === "dropdown") {
          if (filter.param_name) {
            if (filter.name === "emp_type") {
              advanceFilteredObj[filter.param_name] =
                filter.value === "Saudi"
                  ? "Saudi"
                  : filter.value === "Non Saudi"
                    ? "Permanent"
                    : "";
            } else {
              advanceFilteredObj[filter.param_name] = filter.value;
            }
          }
        }
        if (filter.type === "date") {
          if (filter.min_param_name && filter.range) {
            const date0 = new Date(filter.range[0]);
            const formattedDate0 = Moment(date0).format("YYYY-MM-DD");
            advanceFilteredObj[filter.min_param_name] =
              formattedDate0 === "Invalid date" ? "" : formattedDate0;
          }
          if (filter.max_param_name && filter.range) {
            const date1 = new Date(filter.range[1]);
            const formattedDate1 = Moment(date1).format("YYYY-MM-DD");
            advanceFilteredObj[filter.max_param_name] =
              formattedDate1 === "Invalid date" ? "" : formattedDate1;
          }
        }
        if (filter.type === "range") {
          if (filter.min_param_name && filter.value_2) {
            advanceFilteredObj[filter.min_param_name] =
              filter.value_2[0] === 0 ? "" : filter.value_2[0];
          }
          if (filter.max_param_name && filter.value_2) {
            advanceFilteredObj[filter.max_param_name] =
              filter.value_2[1] === 0 ? "" : filter.value_2[1];
          }
        }
      });
      this.loading = true;
      FilterService.getAll(advanceFilteredObj).then((data) => {
        if (data.data.employee !== undefined && data.data.employee !== null) {
          this.selected_items = data.data.employee;
          this.loading = false;
        } else {
          this.retrieve();
        }
      });
    }
  }

  updateFilterFields(fields: any[]) {
    this.filter_fields = fields;
  }
  refreshDateFeild(element: any) {
    // this.retrieve();
    // element.range = [];
  }
  refreshFilters() {
    for (let field of this.filter_fields) {
      field.value = "";
    }
    this.retrieve();
  }
  refreshAdvanceFields() {
    for (let field of this.advance_filter_fields) {
      if (field.type == "text" || field.type == "dropdown") {
        field.value = "";
      } else if (field.type == "range") {
        field.value_2 = [0, 10];
      }else if(field.type === "date"){
        field.range = [];
        field.start = '';
        field.end= '';
      }
      this.retrieve();
    }
  }

  downloadSample() {
    const payload: any = {
      type: "bulk_import_employee_document",
    };
    buitinService.getSheetSamples(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.result[0].builtin_document !== undefined) {
          const anchor = document.createElement("a");
          const fileUrl = process.env.VUE_APP_SERVER + response.data.result[0].builtin_document;
          anchor.href = fileUrl
          anchor.download = "/bulk_import_employee_document";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
        this.showModal('File Has Been Downloaded', [], "success");
      }
      else if (response.status === 204) {
        this.showModal(response?.data?.result, [], "error");
      }
    }).catch((err) => {
      if (err?.response?.data?.result) {
        this.showModal(err?.response?.data?.result, [], "error");
      }
      else {
        this.showModal('Something Went Wrong', [], "error");
      }
    })
  }
  resetFile() {
    this.import_file = null;
    this.import_response = "";
  }
  validateFile(file:any) {
      // Check if a file is selected
      if (!file) {
        return null;
      }
      // Get the file name
      const fileName = file.name;

      // Define allowed file extensions (PDF and Excel)
      const allowedExtensions = ['.xls', '.xlsx','.ods','.xlsm,','.xlsb','.xltx','.xltm','.xlam'];
      // Check if the file has an allowed extension
      const isValid = allowedExtensions.some(extension => fileName.toLowerCase().endsWith(extension));
      return isValid ? true : false;
    }
}
